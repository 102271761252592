import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  btnName: {
    margin: 20
  },
  ps: {
    fontSize:'1vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  lst:{
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      maxWidth: 360,
      overflow: 'auto',
      maxHeight: 300,
    },
  lstItem:{
    fontSize: 11,
  }
})

function FileUploader() {
  const classes = useStyles()
  const fileInputRef = React.useRef()
  let file_name_list = []
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileNames, setFileNames] = useState([])
  const [gridColumns, setGridColumns] = useState(6)

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  }
  const handleUpload = () => {
    // 在这里，你可以处理选中的文件
    for (let i = 0; i < selectedFiles.length; i++) {
      file_name_list.push(selectedFiles[i].name);
    }

    file_name_list = file_name_list.map(file => {
      let simpleName = file.split(' ')[0];
      if (simpleName.includes('.jpg')) {
        simpleName = simpleName.split('.')[0];
      }
      if (simpleName.includes('(')) {
        simpleName = simpleName.split('(')[0];
      }
      return simpleName;
    })
    file_name_list = [...new Set(file_name_list)]
    setGridColumns(4)
    setFileNames(file_name_list)
  }
  const handleButtonClick = () => {
    fileInputRef.current.click();
  }
  return (
    <div className={classes.ps} style={{ height: '100vh', width: `${(10-gridColumns)*10}%`, margin: 'auto' }}>
      <Grid container spacing={3} className={classes.ps}>
        <Grid item xs={gridColumns} style={{fontSize:'1vw', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
          <Button variant="contained" color="secondary" onClick={handleButtonClick}>选择文件</Button>
        </Grid>
        <Grid item xs={gridColumns} style={{fontSize:'1vw', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Button variant="contained" color="secondary" onClick={handleUpload}>获取文件名</Button>
        </Grid>
        <Grid item xs={gridColumns}>
          <Card className={classes.ps}>
            <CardContent>
              <Typography className={classes.title} color="textSecondary" component="h2"> PS： </Typography>
              <Typography color="textSecondary" style={{fontSize:'1vw'}}>点击选择文件
                <br />
                选择所有的图片
                <br />
                点击获取名字即可
                <br />
                会自动去重
                <br />
                去除后缀
                <br />粘贴时使用仅粘贴文本</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.ps}>
      <Grid item xs={fileNames.length>0?gridColumns:0} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}> {fileNames.length>0?(< List className={classes.lst}>
        {fileNames.map((fileName, index) => (
          <ListItem key={index}>{fileName}</ListItem>
        ))}
      </List>):null}</Grid>
      </Grid>
    </div>
  );
}

export default FileUploader