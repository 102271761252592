import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import FileUploader from "./select";
import reportWebVitals from "./reportWebVitals";
import NavBar from "./Appbar";
import myImage from "./assets/1.jpg";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <div
      style={{
        backgroundImage: `url(${myImage})`,
        backgroundSize: "cover",
        opacity: 0.5,
        height: "100vh",
        width: "100%",
        position: "fixed",
        zIndex: -1,
      }}
    ></div>
    <NavBar text='获取编码' />
    <Router>
      <Routes>
        <Route
          path='/'
          element={<FileUploader />}
        />
      </Routes>
    </Router>
  </React.StrictMode>
);
reportWebVitals();
