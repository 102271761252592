import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ModalLogin from "./loginUI";
import Account from "./LoginPaper";
import { BrowserRouter as Router, Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
// import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function NavBar({ text }) {
  const classes = useStyles();
  const clickPath = React.useRef();
  const clickHome = React.useRef();
  function isLoggedIn() {
    return !!localStorage.getItem("user") ? "Account" : "Login";
  }
  function deleteLogin(){
    localStorage.removeItem("user")
    window.location.reload()
  }
  const handleClickPath = () => {
    clickPath.current.click();
  };
  const handleClickHome = () => {
    clickHome.current.click();
  };
  return (
    <div className={classes.root}>
      <AppBar position='static'>
        <Toolbar>
          <Router>
            <Link
              ref={clickHome}
              to='/'
              style={{ display: "none" }}
            >
              Home
            </Link>
            <IconButton
              onClick={handleClickHome}
              edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label='menu'
            >
              <HomeIcon />
            </IconButton>
          </Router>
          <Typography
            variant='h6'
            className={classes.title}
          >
            {text}
          </Typography>
          <ModalLogin text={isLoggedIn()}>
            {isLoggedIn() === "Login" ? (
              <Account Register={true} />
            ) : (
              <Router>
                <Link
                  ref={clickPath}
                  to='/account'
                  style={{ display: "none" }}
                >
                  Account
                </Link>
                <Button
                  color='inherit'
                  onClick={handleClickPath}
                >
                  Account
                </Button>
                <Button
            color='inherit'
            onClick={deleteLogin}
          >
            Login out
          </Button>
              </Router>
            )}
          </ModalLogin>
          <Button
            color='inherit'
            href='https://space.bilibili.com/3842249?spm_id_from=333.1007.0.0'
          >
            By Mai
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
