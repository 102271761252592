import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}))

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Account({Register }) {
  const alertColor={
    200:'success',
    201:'error',
    202:'warning'
  }
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [isRegister, setIsRegister] = React.useState(Register)
  const [alertText, setAlertText] = React.useState('')
  const [alertSeverity, setAlertSeverity] = React.useState('')
  const [account, setAccount] = React.useState(Object.assign({
    account: '',
    password: '',

  }, isRegister ? null : { inviteCode: '' }))
  const click = async () => {
    try {
      const res = await fetch(`http://124.222.59.49:3001/api/${isRegister ? 'login' : 'register'}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(account),
      })
      const {status,body} = res
      console.log(body)
      setAlertSeverity(alertColor[status])
      if (status === 200) {
        setAlertText('登录成功')
        localStorage.setItem('user', JSON.stringify({ account: account.account, password: account.password}))
        window.location.reload()
      } else if (status === 201) {
        setAlertText('密码错误')
      } else if (status === 202) {
        setIsRegister(false)
        setAlertText('账户不存在')
      }
      handleClick()
    } catch (e) {
      console.error(e) 
      }
}
const write = (event) => {
  setAccount({ ...account, [(event.target.id).split('-')[1]]: String(event.target.value) })
}
const handleClick = () => {
  setOpen(true);
};

const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  setOpen(false);
}
return (
  <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)', padding: '20px', borderRadius: '20px' }}>
    <form className={classes.root} noValidate autoComplete="off">
      <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertSeverity}>
          {alertText}
        </Alert>
      </Snackbar>
        <b>{isRegister?'登录':'注册'}</b>
      </div>
      <div>
        <TextField
          id='login-account'
          label="账号"
          onChange={write}
        ></TextField>
        <TextField
          id='login-password'
          label="密码"
          type="password"
          onChange={write}
        ></TextField>
        {isRegister ? <br /> : <TextField
          id='login-inviteCode'
          label="核验码"
          onChange={write}
        ></TextField>}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" color="secondary" onClick={click}>登录</Button>
      </div>
    </form>
  </div>
)
}